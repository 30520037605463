import React, { useState } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/login";
import Account from "./components/main/account/account";
import Dashboard from "./components/Dashboard";
import Course from "./components/main/course/Course";
import Event from "./components/main/event/Event";
import Home from "./components/main/Home/Home";
import Poster from "./components/main/poster/Poster";
import Gift from "./components/main/gift/Gift";
// import Coupon from "./components/main/coupon/coupon";
import Coupon from "./components/main/coupon/coupon";

import Subscription from "./components/main/subs/subscription";


import Addcoupon from "./components/main/coupon/addcoupon";
import Editcoupon from "./components/main/coupon/editcoupon";

import AddActivity from "./components/main/event/AddActivity";
import EditActivity from "./components/main/event/EditActivity";


import AddSubs from "./components/main/subs/AddSubs";
import EditSubs from "./components/main/subs/EditSubs";

import Point from "./components/main/point/point";
import Addredemption from "./components/main/point/AddRedemption";
import Addaccumulation from "./components/main/point/AddAccumulation";





// const NewapiUrl = process.env.REACT_APP_API_URL;
// const NewapiKeys = process.env.REACT_APP_API_URL_KEY;
// const version = process.env.REACT_APP_VERSION

// console.log("BaseApi Url",NewapiUrl);
// console.log("BaseApi Url Key",NewapiKeys);
// console.log("App version",version)

function App() {
  const [navVisible, showNavbar] = useState(false);

  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="home" element={<Home />} />
            <Route index element={<Home />} />
            <Route path="account" element={<Account />} />{" "}
            <Route path="course" element={<Course />} />
            <Route path="event" element={<Event />} />
            <Route path="event/addactivity" element={<AddActivity />} />
            <Route path="event/editactivity" element={<EditActivity />} />
            <Route path="poster" element={<Poster />} />
            <Route path="coupon" element={<Coupon />} />
            <Route path="coupon/addcoupon" element={<Addcoupon />} />
            <Route path="coupon/editcoupon" element={<Editcoupon />} />
            <Route path="gift" element={<Gift />} />
            <Route path="subscription" element={<Subscription/>}/>
            <Route path="subscription/addsubscription" element={<AddSubs/>}/>
            <Route path="subscription/editsubscription" element={<EditSubs/>}/>
            <Route path="point" element={<Point/>}/>
            <Route path="point/addredemption" isEdit={false} element={<Addredemption/>}/>
            <Route path="point/addaccumulation" isEdit={false} element={<Addaccumulation/>}/>
            {/* <Route path="point/updateredemption/:redemptionId" isEdit={true} element={<Addredemption/>}/> */}
            <Route path="point/updateredemption/:redemptionId" element={<Addredemption isEdit={true} />} />
          </Route>
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
