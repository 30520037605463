import React from "react";
import { NavLink } from "react-router-dom";
import "./nav.css";


const NewapiUrl = process.env.REACT_APP_API_URL;
const NewapiKeys = process.env.REACT_APP_API_URL_KEY;
const version = process.env.REACT_APP_VERSION
const publicKey2 = process.env.REACT_APP_PEM



console.log("BaseApi Url",NewapiUrl);
console.log("BaseApi Url Key",NewapiKeys);
console.log("App version",version)
console.log("publicKey2",publicKey2)


function Navbar({  content }) {
  return (
    <nav >
      <div>
        <NavLink className="logo" to="/" >
        </NavLink>
        <div className="links nav-top">
          <NavLink to="/dashboard/home" className="nav-link" >
            <span>{content["Home Management"]}</span>
          </NavLink>
          <NavLink to="/dashboard/course" className="nav-link" >
            <span>{content["Course Management"]} </span>
          </NavLink>
          <NavLink to="/dashboard/event" className="nav-link" >
            <span>{content["Activity Management"]}</span>
          </NavLink>
          <NavLink to="/dashboard/poster" className="nav-link">
            <span>{content["Poster Management"]}</span>
          </NavLink>
          <NavLink to="/dashboard/coupon" className="nav-link">
            <span>{content["Coupon Management"]}</span>
          </NavLink>

          {/* <NavLink to="/dashboard/gift" className="nav-link">
            <span>{content["Gift Management"]}</span>
          </NavLink> */}

          <NavLink to="/dashboard/subscription" className="nav-link">
            <span>{content["Subscription"]}&nbsp;{content["Management"]}</span>
          </NavLink>

          <NavLink to="/dashboard/point" className="nav-link">
            <span>{content["Point"]}&nbsp;{content["Management"]}</span>
          </NavLink>

          <NavLink to="/dashboard/account" className="nav-link">
            <span>{content["Account Management"]}</span>
          </NavLink>
        </div>
      </div>
      <div style={{position: 'absolute', bottom: 0, left: '53px', width: '100%', height: '60px'}}>
        <p> {content["Version"]}:{version}</p>
      </div>
    </nav>
  );
}

export default Navbar;


