import React, { useState, useEffect, useRef } from "react";
import "./point.css";
import axios from "axios";
import pointsJson from "../other/JsonData/point.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import NavBar from "../other/navBar";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";
const API_ENDPOINT = apiBaseUrls.development;

function Addaccumulation({ isEdit }) {
  const { content } = useOutletContext();
  const navigate = useNavigate();

  const point_Benefit_types = pointsJson.Accumulation_rules;

  const [selectedAccumulation_rule, setSelectedAccumulation_rule] = useState("cash");
  const [selectedAccumulation_value, setSelectedAccumulation_value] = useState("");
  const [points, setpoints] = useState("");


  console.log("selectedAccumulation_rule",selectedAccumulation_rule)
  console.log("selectedAccumulation_value",selectedAccumulation_value)
  // const [isFormValid, setIsFormValid] = useState(false);

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };


  const handleAccumulation_rule = (event) => {
    setSelectedAccumulation_rule(event.target.value);
    // setSelectedBenefit_value("");
  };

  const handleAccumulation_valueChange = (event) => {
    const enteredValue = event.target.value;
    let regex;
    regex = /^[1-9]\d{0,3}$/;
    // if (selectedBenefit_types === "discount") {
    //   regex = /^[1-9]\d{0,1}$/; // Default regex
    // } else {
    //   regex = /^[1-9]\d{0,3}$/; // Default regex
    // }

    if (enteredValue === "" || regex.test(enteredValue)) {
      setSelectedAccumulation_value(enteredValue);
    }
  };




  const handleSubmit = async () => {
    try {
        const currentPoints = JSON.parse(localStorage.getItem("Accumulationpoints"));
        const existingPoint = currentPoints.find(
          (point) =>
            point.accumilation_rule === selectedAccumulation_rule
        );

        console.log("existingPoint",existingPoint)
      //   const isexistingPoint = currentPoints.some(
      //     (point) =>
      //       point._id !== currentpointtemp._id &&
      //       point.Benefit_type === selectedBenefit_types &&
      //       point.country === selectedCountries
      //   );

      // if ((isEdit && isexistingPoint) || (!isEdit && existingPoint)) {
      //   Swal.fire({
      //     icon: "warning",
      //     title:  content["Cannot create point"],
      //     text:  content["A matching point already exists for the selected country and benefit type."],
      //     confirmButtonText: content["OK"],
      //   });
      //   return;
      // }

      // const requestBody = {
      //   redemption: {
      //     accumilation_rule: selectedAccumulation_rule,
      //     Points: points,
      //     // delete: false,
      //   },
      //   status: true,
      // };

      const requestBody = {
        accumulation: {
          Points: selectedAccumulation_value,
          accumilation_rule:selectedAccumulation_rule,
        },
        status: true,
      };

      const { encryptedData, SessionToken } = encryptedaes(requestBody);

      config.headers["Session-Token"] = SessionToken;
      const encryptbody = { data: encryptedData };
      const response = await axios.post(API_ENDPOINT + "accumulation/createPoint",
        encryptbody,
        config
      );

      const result = response.data.result;

      if (result === 100) {
        Swal.fire({
          icon: "success",
          title: content["Success"],
          text: isEdit
            ? content["Point updated successfully"]
            : content["Point created successfully"],
          confirmButtonText: content["OK"],
        }).then(() => {
          navigate("/dashboard/point");
        });
      } else {
        if (result === 420) {
          Swal.fire({
            icon: "error",
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
        } else {
          Swal.fire({
            icon: "error",
            title: content["Error"],
            text: content["Failed to create subscription"],
            confirmButtonText: content["OK"],
          });
          console.error(response.data.activities);
        }
      }
    } catch (error) {
      console.error("Error creating activity", error);
    }
  };

  return (
    <div>
      <NavBar />
      <div>
        <span>
          <FontAwesomeIcon icon={faChevronLeft} size="xl" />
        </span>
        &nbsp;
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => navigate("/dashboard/point")}
        >
          {content["Back to previous"]}
        </span>
      </div>
      <br />
      <br />
      <br />

      <div className="box-parent-acc">

        <div className="box-one-acc">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Accumulation rule"]}
        </div>

        <div className="box-two-acc">
          <select
            className="box-dropdownforCountry"
            value={selectedAccumulation_rule}
            onChange={handleAccumulation_rule}
          >
            {point_Benefit_types.map((types) => (
              <option key={types.id} value={types.id}>
                {types.name}
              </option>
            ))}
          </select>
        </div>

        <div className="box-three-acc">
          <span style={{ color: "red" }}>*</span>&nbsp;
          {content["Points"]}
        </div>
        <div className="box-four-acc">
          <input
            type="text"
            placeholder={content["Value"]}
            className="box-input-class"
            value={selectedAccumulation_value}
            onChange={handleAccumulation_valueChange}
          />
        </div>

        <div className="box-box-btn-acc">
          <button
            className="box-handle-save"
            onClick={handleSubmit}
            // disabled={!isFormValid}
          >
             
            {content["Save"]}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Addaccumulation;
