import React, { useState, useEffect, useRef } from "react";
import "./point.css";
import axios from "axios";
import pointsJson from "../other/JsonData/point.json";
import countriesJson from "../other/JsonData/countries.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
  faChevronLeft,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import NavBar from "../other/navBar";
import { useOutletContext } from "react-router-dom";
import { useNavigate , useParams  } from "react-router-dom";
import { apiBaseUrls , apiKeys} from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";
const API_ENDPOINT = apiBaseUrls.development;




function Addredemption({ isEdit }) {
  const { content } = useOutletContext();
  const navigate = useNavigate();
  const { redemptionId } = useParams();
  const countries = countriesJson.countries;
  const point_Constraint = pointsJson.Constraint;
  const point_Benefit_types = pointsJson.Benefit_types;

  const [inputData, setInputData] = useState([
    { rule: "count_limit_per_month", value: "Select" },
  ]);
  const [selectedCountries, setSelectedCountries] = useState("GB");
  const [selectedBenefit_types, setSelectedBenefit_types] = useState("cash");
  const [selectedBenefitUnit, setSelectedBenefitUnit] = useState("");
  const [selectedBenefit_value, setSelectedBenefit_value] = useState("");
  const [points, setpoints] = useState("");
  const [currentpointtemp , setCurrentpoint] = useState("")
  const [isFormValid, setIsFormValid] = useState(false);


  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };
  
  const handlepointsChange = (event) => {
    const enteredValue = event.target.value;
    const regex = /^[1-9]\d{0,3}$/;

    if (enteredValue === "" || regex.test(enteredValue)) {
      setpoints(enteredValue);
    }
  };

console.log("currentpoint",currentpointtemp)
  useEffect(() => {
    if (isEdit && redemptionId) {
      const redemptionData = localStorage.getItem("Redemptionpoints");

      
    if (redemptionData) {
      try {
        const redemptionDataJSON = JSON.parse(redemptionData);
        const idToMatch = redemptionId;
        const matchedredmption = redemptionDataJSON.find(
          (redemption) => redemption._id === idToMatch
        );
        setCurrentpoint(matchedredmption)
        if (matchedredmption) {
          setInputData(
            matchedredmption.Constraint.map((constraint) => ({
              rule: constraint.rule,
              value: constraint.value,
            }))
          );
        
          setSelectedCountries(matchedredmption.country);
          setSelectedBenefit_types(matchedredmption.Benefit_type);
          setSelectedBenefit_value(matchedredmption.Benefit_Value);
          setSelectedBenefitUnit(matchedredmption.Benefit_unit);
          setpoints(matchedredmption.Points);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Data with key 'Activities' not found in local storage.");
    }
    }
  }, [isEdit, redemptionId]);


  console.log("inputData",inputData)
  const handleBenefit_valueChange = (event) => {
    const enteredValue = event.target.value;
    let regex;
  
    if (selectedBenefit_types === "discount") {
      // regex = /^[1-9]\d{0,1}$/; // Default regex
      regex = /^(100|[1-9]?\d)$/; // Default regex 
    } else {
      regex = /^[1-9]\d{0,3}$/; // Default regex
    }
  
    if (enteredValue === "" || regex.test(enteredValue)) {
      setSelectedBenefit_value(enteredValue);
    }
  };

  const handleAddClick = () => {
    // setInputData([...inputData, { rule: null, value: "" }]);
    setInputData([...inputData, { rule: "monthly_subscription", value: "true" },]);
  };

  
  

  const handleRemoveClick = (index) => {
    if (inputData.length > 1) {
      const updatedData = [...inputData];
      updatedData.splice(index, 1);
      setInputData(updatedData);
    }
  };


  const handleInputChange = (index, key, value) => {
    const updatedData = [...inputData];
    updatedData[index][key] = value;
    if (value === "annual_subscription" || value === "halfyearly_subscription" || value === "monthly_subscription") {
      const subscriptionIndex = updatedData.findIndex(item => item.rule === value);
      if (subscriptionIndex !== -1) {
        updatedData[subscriptionIndex].value = "true";
      }
    }
    setInputData(updatedData);
  };

  
  const handleCountryChange = (event) => {
    setSelectedCountries(event.target.value);
  };

  const handleBenefit_types = (event) => {
    setSelectedBenefit_types(event.target.value);
    setSelectedBenefit_value("")
  };

  useEffect(() => {
    let benefitUnit = "";
    if (selectedBenefit_types === "cash") {
      benefitUnit =
        selectedCountries === "GB"
          ? "£"
          : selectedCountries === "DE"
          ? "€"
          : "";
    } else if (selectedBenefit_types === "discount") {
      benefitUnit = "%";
    } else if (selectedBenefit_types === "subscription") {
      benefitUnit = "Day";
    }
    setSelectedBenefitUnit(benefitUnit);
  }, [selectedBenefit_types, selectedCountries]);


  useEffect(() => {
    const isInputDataValid = inputData.every(
      (data) => data.rule !== null && data.value.trim() !== ""
    );
    const isPointsValid = points.trim() !== "";
    const isBenefitValueValid = selectedBenefit_value.trim() !== "";
  
    setIsFormValid(
      isPointsValid &&
      isBenefitValueValid &&
      isInputDataValid &&
      selectedCountries !== "" &&
      selectedBenefit_types !== "" &&
      selectedBenefitUnit !== ""
    );
  }, [inputData, points, selectedBenefit_value, selectedCountries, selectedBenefit_types, selectedBenefitUnit]);
  
  const handleSubmit = async () => {
    try {
      const currentPoints = JSON.parse(localStorage.getItem("Redemptionpoints"));
      const existingPoint = currentPoints.find(
        (point) =>
          point.Benefit_type === selectedBenefit_types &&
          point.country === selectedCountries
      );

      // const isexistingPoint = currentPoints.some(
      //   (point) =>
      //     point._id !== currentpointtemp._id &&
      //     point.Benefit_type === selectedBenefit_types &&
      //     point.country === selectedCountries
      // );

      const isexistingPoint = currentPoints.some(
        (point) =>
          point._id !== currentpointtemp._id &&
          point.Points === points &&
          point.country === selectedCountries
      );


    //   if (!isEdit) {
    //      if (existingPoint) {
    //     // If a matching point exists, show a warning message using Swal
    //     Swal.fire({
    //       icon: "warning",
    //       title: "Cannot create point",
    //       text: "A matching point already exists for the selected country and benefit type.",
    //       confirmButtonText: content["OK"],
    //     });
    //     return; // Prevent further execution of creating a new point
    //   }
    // }


    // if ((isEdit && isexistingPoint) || (!isEdit && existingPoint)) {
    //   // If a matching point exists, show a warning message using Swal
    //   Swal.fire({
    //     icon: "warning",
    //     title:  content["Cannot create point"],
    //     text:  content["A matching point already exists for the selected country and benefit type."],
    //     confirmButtonText: content["OK"],
    //   });
    //   return; // Prevent further execution of creating a new point
    // }
    
  

    // Check for duplicate rules in the inputData array
    const ruleSet = new Set();
    let isDuplicateRule = false;
    inputData.forEach((data) => {
      if (ruleSet.has(data.rule)) {
        isDuplicateRule = true;
      } else {
        ruleSet.add(data.rule);
      }
    });

    if (isDuplicateRule) {
      // If duplicate rule is found, show alert message
      Swal.fire({
        icon: "warning",
        title: content["Duplicate rule"],
        text: content["You cannot select the same rule more than once."],
        confirmButtonText: content["OK"],
      });
      return; // Prevent further execution
    }

  const hasSelectOption = inputData.some(data => {
    // Check if the rule is null or "Select"
    if (data.rule === null || data.rule === "Select") {
      return true; // Return true if "Select" option is found
    }
    // Check if the value is null or "Select" (only applicable for certain rules)
    if (data.rule === "count_limit_per_month" && (data.value === null || data.value === "Select")) {
      return true; // Return true if "Select" option is found
    }
    return false; // Return false otherwise
  });

  if (hasSelectOption) {
    // If any option is still "Select", show an alert message
    Swal.fire({
      icon: "warning",
      title: content["Select proper option"],
      text: content["Please select a proper option for all rules."],
      confirmButtonText: content["OK"],
    });
    return; // Prevent further execution
  }



  const isSubscriptionSelected = inputData.some(
    (data) =>
      data.rule === "monthly_subscription" ||
      data.rule === "halfyearly_subscription" ||
      data.rule === "annual_subscription"
  );

  if (!isSubscriptionSelected) {
    Swal.fire({
      icon: "warning",
      title: content["Select Subscription"],
      text: content["Please select at least one subscription rule."],
      confirmButtonText: content["OK"],
    });
    return; 
  }

      const requestBody = {
        redemption: {
          ...(isEdit && { _id: redemptionId }),
          country: selectedCountries,
          Points: points,
          Benefit_type: selectedBenefit_types,
          Benefit_Value: selectedBenefit_value,
          Benefit_unit: selectedBenefitUnit,
          Constraint: inputData,
          delete: false,
        },
        status: true,
      };
  
      const { encryptedData, SessionToken } = encryptedaes(requestBody);
  
      config.headers["Session-Token"] = SessionToken;
      const encryptbody = { data: encryptedData };
      const response = await axios.post(
        isEdit
          ? API_ENDPOINT + "redemption/editPoint"
          : API_ENDPOINT + "redemption/createPoint",
        encryptbody,
        config
      );
  
      console.log("isEdit->",isEdit)
      const result = response.data.result;
  
      if (result === 100) {
        // Subscription created successfully, show SweetAlert2 success message and navigate
        Swal.fire({
          icon: "success",
          title: content["Success"],
          text: isEdit ? content["Point updated successfully"] : content["Point created successfully"],
          confirmButtonText: content["OK"],
        }).then(() => {
          navigate("/dashboard/point"); // Redirect to /dashboard/subscription
        });
      } else {
        if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: "error",
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
        } else {
          // Handle any other error from the API, show SweetAlert2 error message
          Swal.fire({
            icon: "error",
            title: content["Error"],
            text: content["Failed to create subscription"],
            confirmButtonText: content["OK"],
          });
          console.error(response.data.activities);
        }
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error creating activity", error);
    }
  };
  
  return (
    <div>
      <NavBar />
      <div>
        <span>
          <FontAwesomeIcon icon={faChevronLeft} size="xl" />
        </span>
        &nbsp;
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => navigate("/dashboard/point")}
        >
          {content["Back to previous"]}
        </span>
      </div>
      <br />

      <div className="box-parent">
        {/* <div className="box-one">&nbsp;&nbsp; Create Point</div> */}
        <div className="box-two">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Country"]}
        </div>
        <div className="box-three">
          <select
            className="box-dropdownforCountry"
            value={selectedCountries}
            onChange={handleCountryChange}
          >
            {countries.map((country) => (
              <option key={country._id} value={country.code}>
                {country.country}
              </option>
            ))}
          </select>
        </div>
        <div className="box-four">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Points"]}
        </div>
        <div className="box-five">
          {/* <input className="box-input-class" /> */}
          <input
            type="text"
            // placeholder={content["Current price"]}
            placeholder="Points"
            className="box-input-class"
            value={points}
            onChange={handlepointsChange}
          />
        </div>
        <div className="box-six">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Benefit type"]}
        </div>
        <div className="box-seven">
          <select
            className="box-dropdownforCountry"
            value={selectedBenefit_types}
            onChange={handleBenefit_types}
          >
            {point_Benefit_types.map((types) => (
              <option key={types.id} value={types.id}>
                {types.name}
              </option>
            ))}
          </select>
        </div>
        <div className="box-eight">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Benefit Value"]}
        </div>
        <div className="box-nine">
          {/* <input className="box-input-class" /> */}
          <input
            type="text"
            // placeholder={content["Current price"]}
            placeholder="Value"
            className="box-input-class"
            value={selectedBenefit_value}
            onChange={handleBenefit_valueChange}
          />
        </div>
        <div className="box-ten">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Benefit unit"]}
        </div>
        <div className="box-eleven">
          <span>{selectedBenefitUnit}</span>
        </div>
        <div className="box-div12">
          <span style={{ color: "red" }}>*</span>&nbsp;{content["Constraint"]}
        </div>
        <div className="box-div13">
          {inputData.map((data, index) => (
            <div className="box-input" key={index}>

              
              <select
                value={data.rule}
                onChange={(e) =>
                  handleInputChange(index, "rule", e.target.value)
                }
                className="box-dropdownforCountry"
              >
                {Object.values(point_Constraint[0]).map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              &nbsp;
              {data.rule === "count_limit_per_month" && (
              <select
                value={data.value}
                onChange={(e) =>
                  handleInputChange(index, "value", e.target.value)
                }
                className="box-dropdownforCountry"
              >
                <option value={null}>Select</option>
                {data.rule &&
                  point_Constraint[0][data.rule].options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
              )}

              <span className="box-removebtn">
                <button
                  onClick={() => handleRemoveClick(index)}
                  disabled={inputData.length === 1}
                  className="box-box-remove"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </span>
              <br />
            </div>
          ))}

          <div className="box-Addbtn">
            <button
              onClick={handleAddClick}
              disabled={inputData.length === 4}
              className="box-box-add"
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>

          <div className="box-box-btn">
            <button className="box-handle-save" onClick={handleSubmit} disabled={!isFormValid}> {content["Save"]}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addredemption;
